import React from "react";
import sharedStyles from "features/PlanOptions/style.module.scss";
import { mixpanel } from "components/Mixpanel";
import Button from "components/Button";
import ComparingTable from "../ComparingTable";

const comparableData = [
    {
        title: "Audit",
        header1: "Basic",
        header2: "Pro",
        color1: "#333333",
        color2: "#1679FB",
        rows: [
            {
                title: "Optimize",
                info:
                    "Identify opportunities to improve your overall marketing performance by optimizing media allocation across campaigns and platforms.",
                starter: false,
                pro: true,
            },
            {
                title: "On-Page SEO",
                info:
                    "Review the SEO health of your web pages and ensure that you are following industry-standard best practices.",
                starter: true,
                pro: true,
            },
            {
                title: "Placement Auditing",
                info:
                    "Easily exclude ineffective ad placements on the Google Display Network and use our autopilot feature to automate the exclusion of low-performing placements.",
                starter: false,
                pro: true,
            },
        ],
    },
    {
        title: "Monitor",
        rows: [
            {
                title: "Anomalies",
                info:
                    "Detect and be notified of unexpected behavior and data trends on your website and connected ad platforms using our advanced machine learning algorithms.",
                starter: "Limited",
                pro: true,
            },
            {
                title: "Landing Page Monitoring",
                info:
                    "Detect and be notified of access issues on all destination URLs from connected ad platforms as well as your top-performing organic webpages.",
                starter: "Organic Pages Only",
                pro: true,
            },
            {
                title: "SEO Rank Monitoring",
                info:
                    "Track changes in organic search ranking for specific keywords and monitor against the performance of specified competitor domains.",
                starter: false,
                pro: true,
                subRows: [
                    { title: "- Keywords", info: "", pro: "50 keywords / Client Account" },
                    { title: "- Competitors", info: "", pro: "10 competitors / Client Account" },
                ],
            },
            {
                title: "Budget Monitoring",
                info:
                    "Set monthly budgets and receive alerts when connected ad platforms are overspending or underspending.",
                starter: true,
                pro: true,
            },
            {
                title: "Enhanced Budget Monitoring",
                info:
                    "Set budgets and track the combined media spend of a group of campaigns from one or more ad platforms across a custom date range while receiving alerts when the group of campaigns is overspending or underspending.",
                starter: false,
                pro: true,
            },
        ],
    },
    {
        title: "Analyze",
        rows: [
            {
                title: "Suggestions",
                info:
                    "Identify new ways to improve the performance of your ad campaigns and your overall digital marketing by reviewing AI-powered marketing suggestions.",
                starter: false,
                pro: true,
            },
            {
                title: "Audience Segments",
                info:
                    "Identify your high-performing and low-performing audiences to better inform your marketing initiatives.",
                starter: false,
                pro: true,
            },
            {
                title: "Forecast",
                info: "Project future performance of goals and media spend while comparing to historical performance.",
                starter: false,
                pro: true,
            },
        ],
    },
    {
        title: "Reporting",
        rows: [
            {
                title: "Summary Reports",
                info: "Quickly review your overall digital marketing performance and view high-level platform reports.",
                starter: true,
                pro: true,
            },
            {
                title: "Goal Tracking",
                info:
                    "Set meaningful goals for a variety of metrics and track progress throughout the month in our aggregate summary report.",
                starter: true,
                pro: true,
            },
            {
                title: "Shareable Reports",
                info:
                    "Easily share your summary report with a white-labeled shareable report link (no login required) or invite reporting-only users at no additional cost.",
                starter: true,
                pro: true,
            },
        ],
    },
    {
        title: "Access",
        rows: [
            {
                title: "Support",
                info:
                    "Access published resources (text and video) as well as connect with product specialists who can help you get the most out of our platform.",
                starter: "Email",
                pro: "Live Chat*",
            },
            {
                title: "User Seats",
                starter: "Unlimited",
                pro: "Unlimited",
            },
        ],
    },
];

export default ({
    toggleDisplay,
    daysLeftInTrial,
    isTrial,
    downgraded = false,
    dismissTrialWall,
    forcePlanOptions,
    toggleOpen,
    userId,
}) => {
    const getMessaging = () => {
        if (isTrial) {
            return {
                title: (
                    <span>
                        Your 14-day free trial of our <strong style={{ color: "#6634FF" }}>Pro Plan</strong> ends in{" "}
                        {daysLeftInTrial} days.
                    </span>
                ),
                subTitle: "Choose an option below.",
                buttonText: "Continue Free Trial",
            };
        }
        if (downgraded) {
            return {
                title: "Upgrade Your Plan",
                subTitle: "Upgrade your plan to best suit your needs.",
                buttonText: "Current Plan",
            };
        }
        return {
            title: "Your 14-day free trial is complete.",
            subTitle: "Choose an option below.",
            buttonText: "Downgrade to Basic",
        };
    };

    const sendToMixpanel = (eventName) => {
        return mixpanel.track(eventName, {
            Location: "Options",
            "In Freemium": forcePlanOptions,
            "In Trial": isTrial,
        });
    };

    const FreeClickEvents = async () => {
        if (isTrial) {
            const datetime = new Date().toISOString();

            sendToMixpanel("Plan Options - Continue Trial");
            await dismissTrialWall(datetime, userId);
            toggleOpen(false);
        } else {
            sendToMixpanel("Plan Options - Downgrade");
            return toggleDisplay("downgrade");
        }
    };

    return (
        <div>
            <div className={sharedStyles.heading}>
                <h2>{getMessaging().title}</h2>
                <p>{getMessaging().subTitle}</p>
            </div>
            <div className={sharedStyles.options}>
                <div className={`${sharedStyles.option} ${sharedStyles.basic} ${isTrial && sharedStyles.inTrial}`}>
                    <div className={sharedStyles.top}>
                        <h2>{isTrial ? "Free Trial" : "Basic"}</h2>
                    </div>
                    <div className={sharedStyles.bottom}>
                        {!isTrial && (
                            <React.Fragment>
                                <span className={sharedStyles.price}>{isTrial ? "Pro Trial" : "$0"}</span>
                                <span className={sharedStyles.info}>
                                    <strong>10</strong> client accounts
                                    <br />
                                    Unlimited Users
                                </span>
                            </React.Fragment>
                        )}

                        {isTrial && (
                            <span className={sharedStyles.bigText}>
                                You are trialling our <strong>Pro Plan</strong>.
                            </span>
                        )}

                        {downgraded ? (
                            <Button disabled>{getMessaging().buttonText}</Button>
                        ) : (
                            <Button onClick={FreeClickEvents}>{getMessaging().buttonText}</Button>
                        )}
                    </div>
                </div>
                <div className={`${sharedStyles.option} ${sharedStyles.pro}`}>
                    <div className={sharedStyles.top}>
                        <h2>Pro</h2>
                    </div>
                    <div className={sharedStyles.bottom}>
                        <span className={sharedStyles.prePrice}>Starting at</span>
                        <span className={sharedStyles.price}>
                            $249<small> /mo</small>
                        </span>
                        <Button
                            vibrantBlue
                            onClick={() => {
                                mixpanel.track("Plan Options - Book Meeting (Upgrade Card)", {
                                    Location: "Options",
                                    "In Freemium": forcePlanOptions,
                                    "In Trial": isTrial,
                                });
                                toggleDisplay("upgrade");
                            }}
                        >
                            Upgrade now
                        </Button>
                    </div>
                </div>
                <div className={`${sharedStyles.option} ${sharedStyles.custom}`}>
                    <div className={sharedStyles.bottom}>
                        <h2>Talk with a product specialist</h2>
                        <span className={sharedStyles.info} style={{ marginTop: "1rem" }}>
                            Learn how we can help you reduce manual work and improve your advertising performance.
                        </span>
                        <Button
                            onClick={() => {
                                mixpanel.track("Plan Options - Book Meeting", {
                                    Location: "Options",
                                    "In Freemium": forcePlanOptions,
                                    "In Trial": isTrial,
                                });
                                window.open("https://meetings.hubspot.com/arjun64", "_blank");
                            }}
                        >
                            Schedule a meeting
                        </Button>
                    </div>
                </div>
            </div>
            {/* <ComparingTable data={comparableData} /> */}
        </div>
    );
};
